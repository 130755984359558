// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add this to your existing CSS file or create a new one */

.table-header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    padding: 10px 20px;
  }
  
  .table-header > div {
    width: calc(100% / 6); /* Equal width for each column */
    text-align: center;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/services.css"],"names":[],"mappings":"AAAA,2DAA2D;;AAE3D;IACI,aAAa;IACb,8BAA8B;IAC9B,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,qBAAqB,EAAE,gCAAgC;IACvD,kBAAkB;EACpB","sourcesContent":["/* Add this to your existing CSS file or create a new one */\n\n.table-header {\n    display: flex;\n    justify-content: space-between;\n    font-weight: bold;\n    padding: 10px 20px;\n  }\n  \n  .table-header > div {\n    width: calc(100% / 6); /* Equal width for each column */\n    text-align: center;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
