const sfMgrIp = process.env.REACT_APP_SERVICE_FATHER_MGR_REPORT_IP;
//const sfMgrIp ="https://sfm.dexynth.com"
export const fetchStatusBackend = async () => {
  const url = `${sfMgrIp}/status`;
  const response = await fetch(url);

  return fetch(url)
    .then((response) => response.json())
    .then((res) => res.result)
    .catch((error) => []);
};

export const login = async ({ username, password }) => {
  const url = `${sfMgrIp}/login`;
  console.log("URL",url)
  const body = {
    user: username,
    password: password,
  };
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  return response;
};

export const validateToken = async ({ token }) => {
  const url = `${sfMgrIp}/validateToken`;
  const body = {
    token: token,
  };
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  return response;
};
