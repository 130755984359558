import React, { useState } from "react";
import "./LoginForm.css";
import { login } from "../services/backend";

function LoginForm({ onLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const okResponse = {
    token: "eyeerlkoi8998ejej0",
    ok: true,
  };
  const nokResponse = {
    ok: false,
  };

  const handleSubmit = async (e) => {
    //let response = nokResponse;
    e.preventDefault();
    if (!username || !password) {
      setError("Username and password are required");
      return;
    }
    const response = await login({ username, password });
    const data = await response.json();

    //const data = response;
    if (response.ok) {
      onLogin(data.token); // Call the onLogin function with the received token
    } else {
      setError(data.message); // Display error message
    }
  };

  return (
    <div className="disclaimer-modal-overlay">
      <div className="disclaimer-modal">
        <div className="disclaimer-modal-content">
          <form onSubmit={handleSubmit}>
            <div>
              <label>Username:</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div>
              <label>Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button type="submit">Login</button>
            {error && <div style={{ color: "red" }}>{error}</div>}
          </form>{" "}
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
