import "./App.css";
import useServiceFatherMgr from "./hooks/useServiceFatherMgr";
import { ServicesInfo } from "./components/services";
import LoginForm from "./components/LoginForm";
import LabeledCheckbox from "./components/labeledCheckbox";

import { validateToken } from "./services/backend";
import { useState, useEffect } from "react";

import response from "./mooks/search.json";

function MainApp({ onLogout }) {
  const [sort, setSort] = useState(false);
  const [sortByHost, setSortByHost] = useState(true);
  const [hideDisabled, setHideDisabled] = useState(true);
  const [filterText, setFilterText] = useState("");

  const handleLogout = () => {
    // Call the onLogout function passed from the App component
    onLogout();
  };

  const { servicesInfo } = useServiceFatherMgr({
    sort,
    hideDisabled,
    sortByHost,
    filterText, // Pass filterText to the hook
  });

  const handleSortByHost = () => {
    setSortByHost(!sortByHost);
  };

  const handleSort = () => {
    setSort(!sort);
  };

  const handleHideDisabled = () => {
    setHideDisabled(!hideDisabled);
  };

  const handleFilterTextChange = (event) => {
    setFilterText(event.target.value);
  };

  return (
    <div className="page">
      <header>
        <h1>Service Father Manager</h1>
        <div className="header-controls">
          <div className="header-control">
            <LabeledCheckbox
              label="Sort by Hostname"
              value={sortByHost}
              setValue={handleSortByHost}
              id="sortByHostChk"
            />
          </div>
          <div className="header-control">
            <LabeledCheckbox
              label="Hide Disabled"
              value={hideDisabled}
              setValue={handleHideDisabled}
              id="hideDisabledChk"
            />
          </div>
          <div className="header-control">
            <label htmlFor="filterInput">Filter by Text:</label>
            <input
              type="text"
              id="filterInput"
              value={filterText}
              onChange={handleFilterTextChange}
            />
          </div>
          <button onClick={handleLogout}>Logout</button>
        </div>
      </header>

      <main>
        <ServicesInfo servicesInfo={servicesInfo} />
      </main>
    </div>
  );
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check if there's a token stored in local storage
    const token = localStorage.getItem("token");
    if (token) {
      validateToken({ token }).then((response) => {
        if (response.ok) setIsLoggedIn(true);
      });
    }
  }, []);

  const handleLogin = (token) => {
    // Store token in localStorage or sessionStorage for persistent login
    localStorage.setItem("token", token);
    setIsLoggedIn(true);
  };
  const handleLogout = () => {
    // Remove token from localStorage on logout
    localStorage.removeItem("token");
    setIsLoggedIn(false);
  };

  return (
    <div className="App">
      {isLoggedIn ? (
        <MainApp onLogout={handleLogout} />
      ) : (
        <LoginForm onLogin={handleLogin} />
      )}
    </div>
  );
}

export default App;
