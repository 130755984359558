import { useState, useMemo, useEffect } from "react";
import useSWR from "swr";

import { fetchStatusBackend } from "../services/backend";
import response from "../mooks/search.json";

export default function useServiceFatherMgr({
  hideDisabled,
  sort,
  sortByHost,
  filterText,
}) {
  const [services, setServices] = useState([]);

  const fetchStatus = async (...args) => {
    const statusData = await fetchStatusBackend();
    setServices(statusData);
  };

  const { data, error } = useSWR([`fetchStatus`], fetchStatus, {
    refreshInterval: 3000,
  });

  /*const sortedServices = [...services].sort((b, a) =>
    a.name.localeCompare(b.name)
  );*/

  const sortedServices = useMemo(() => {
    console.log("sorssst", sortByHost);
    return sortByHost
      ? [...services].sort((a, b) => a.hostname.localeCompare(b.hostname))
      : services;
  }, [sortByHost, services]);

  /*
  const filteredservices = useMemo(() => {
    //console.log("hideDisabled", sortedServices);
    return hideDisabled
      ? sortedServices.filter((srv) => srv.enabled)
      : sortedServices;
  }, [hideDisabled, sortedServices]);*/

  const filteredServices = useMemo(() => {
    const filteredByEnabled = hideDisabled
      ? sortedServices.filter((srv) => srv.enabled)
      : sortedServices;

    return filterText
      ? filteredByEnabled.filter((srv) =>
          srv.service.toLowerCase().includes(filterText.toLowerCase())
        )
      : filteredByEnabled;
  }, [hideDisabled, sortedServices, filterText]);

  return { servicesInfo: filteredServices };
}
